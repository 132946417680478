import * as Sentry from "@sentry/nextjs";
import type { Session } from "@supabase/supabase-js";
import { Loader } from "lucide-react";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Progress } from "~/components/ui/progress";
import type { ReportsDataHook } from "~/data-utils/local-db";
import { useGetHotelReservationData } from "~/data-utils/local-db";
import type { UserProfile } from "~/server/db-types";
import { api } from "~/utils/api";

interface ConfigContextInterface {
  config: Record<string, any>;
  userProfile: UserProfile | null;
  hotelId: string | undefined;
  hotelData: ReportsDataHook;
  supabaseSession: Session | null | undefined;
}

export const ConfigContext = React.createContext<ConfigContextInterface>({
  // not good idea to add flags here as will cause re-render all app on get foucs
  config: {},
  userProfile: null,
  hotelId: undefined,
  // @ts-ignore
  hotelData: { data: null, isReady: false, isLoading: true },
});

export const ConfigProvider = ({
  supabaseSession,
  authEventsReceived,
  pageNeedUser,
  pageNeedHotelData,
  children,
}: {
  supabaseSession: Session | null | undefined;
  authEventsReceived: boolean;
  pageNeedUser: boolean;
  pageNeedHotelData: boolean;
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const path = router.asPath;
  const hotelId: string | undefined = router.query.hotelId
    ? String(router.query.hotelId)
    : undefined;

  const upsertUserProfile = api.auth.upsertUserProfile.useMutation();
  const { data: userProfile, refetch } = api.auth.getUserProfile.useQuery(
    undefined,
    {
      enabled: false,
      placeholderData: (prev) => prev ?? null,
    },
  );

  const { data: result } = api.auth.getPublicAccessTokenForTrigger.useQuery(
    undefined,
    {
      enabled: !!userProfile,
    },
  );

  if (result) {
    localStorage.setItem("publicAccessToken", result.publicAccessToken);
  }

  const [authReady, setAuthReady] = useState(false);
  const userProfileId = userProfile?.id;

  const [hotelDataDownloadProgress, setHotelDataDownloadProgress] =
    useState<number>(0);
  const hotelData = useGetHotelReservationData(
    hotelId,
    userProfileId,
    setHotelDataDownloadProgress,
  );
  // const hotelData = { data: null, isReady: false, isLoading: true };

  const onAuth = useCallback(
    async (sessionClient: Session | null) => {
      const id = sessionClient?.user?.id || null;

      if (id && userProfileId !== id) {
        console.log(`onAuth: muly:has id ${id} !== ${userProfileId}`, {
          userProfile,
          sessionClient,
        });

        const userReceived = await refetch();
        if (!userReceived.data) {
          console.log(
            `muly:onAuth user profile not found, should not happen often, try create the profile`,
          );

          const { hotelId, invitationKey } = router.query;
          await upsertUserProfile.mutateAsync({
            hotelId: hotelId as string,
            invitationKey: invitationKey as string,
          });
          const userReceivedTake2 = await refetch();
          if (!userReceivedTake2) {
            console.error(`muly:onAuth user profile not found, take 2`);
            throw new Error(`muly:onAuth user profile not found, take 2`);
          }
        }
        console.log(`muly:has id, refetch ${id}`, {
          userReceived: userReceived.data,
        });
      }

      if (pageNeedUser && !id && authEventsReceived) {
        console.log(`muly:should redirect to login page`, {
          sessionClient,
        });
        await router.replace("/login");
      }

      console.log(`muly:setAuthReady, ${id ? id : "NO USER"}`, {
        id,
        userProfile_STALED_fixed_before_refetch: userProfile,
        userProfileId,
        sessionClient,
        authEventsReceived,
        pageNeedUser,
      });
      setAuthReady(true);

      // Sentry.setUser(
      //   id
      //     ? {
      //         id,
      //         email: sessionClient?.user?.email || "unknown",
      //         username: user?.name || sessionClient?.user?.email || "unknown",
      //       }
      //     : null,
      // );
      // Sentry.setTag("agency", organizationData?.name || "unknown");
    },
    [
      setAuthReady,
      userProfileId,
      refetch,
      upsertUserProfile,
      userProfile,
      authEventsReceived,
      pageNeedUser,
      router,
    ],
  );

  useEffect(() => {
    Sentry.setUser(
      userProfile
        ? {
            id: userProfile.id,
            email: userProfile.email || "unknown",
            username: userProfile.name || userProfile.email || "unknown",
          }
        : null,
    );
    console.log(`muly:config-context:Sentry`, {
      userProfileId,
      userProfile,
    });
  }, [userProfile, userProfileId]);

  useEffect(() => {
    console.log(`muly:config-context:useffect`, {
      supabaseSession,
      authEventsReceived,
    });
    if (authEventsReceived) {
      onAuth(supabaseSession || null).catch((err) => {
        console.error(`muly:onAuthStateChange:onAuth:error`, err);
      });
    }
    // don't add onAuth to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseSession, authEventsReceived /*, onAuth*/]);

  const hotelName = hotelData.data?.hotel?.name;
  useEffect(() => {
    Sentry.setTag("hotel", hotelName);
  }, [hotelName]);

  if (
    /*!flagBag.flags || */
    // supabaseSession === undefined ||
    !authReady ||
    (pageNeedUser && !userProfile) ||
    (pageNeedHotelData && !hotelData.isReady)
  ) {
    // console.log(`muly:ConfigProvider:Loading ${hotelDataDownloadProgress}`, {
    //   authEventsReceived,
    //   authReady,
    //   userProfile,
    //   pageNeedUser,
    //   pageNeedHotelData,
    //   supabaseSession,
    //   isReady: hotelData.isReady,
    //   hotelData,
    // });

    const showProgressBar =
      hotelDataDownloadProgress > 0 &&
      !isNaN(hotelDataDownloadProgress) &&
      hotelData.isLoading &&
      (!hotelData.data.hotel || hotelData.data.hotel.id === hotelId);

    // console.log(
    //   `setHotelDataDownloadProgress:render ${hotelDataDownloadProgress}`,
    //   {
    //     showProgressBar,
    //     hotelId,
    //     hotelData,
    //     h: hotelData.data?.hotel?.id,
    //   },
    // );
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Loader className="size-10 animate-spin" />
        {showProgressBar && (
          <>
            <div className="absolute mt-16 text-center">
              <p className="mt-16">
                Setting up the system & importing data,
                <br />
                It may take a couple of minutes.
              </p>
            </div>
            <div className="absolute mt-52 w-full">
              <div className="mx-auto max-w-sm">
                <Progress
                  value={Math.min(
                    100,
                    Math.max(0, hotelDataDownloadProgress * 100),
                  )}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  const value = {
    userProfile: userProfile || null,
    supabaseSession,
    config: {},
    hotelId,
    hotelData,
  };

  // console.log(`muly:ConfigProvider:render `, { authReady });
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error("useConfigContext must be used within a ConfigProvider");
  }
  return context;
};
